body{
    color: #000;
    font-family: "Open Sans";
    background: #f5f5f5;
}
a{
    text-decoration: none; 
    color: #699c33;
}

.btn{
    border-radius: 0;
    &.btn-primary{
        font-size: 17px;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 30px;
        color: #FFF;
    }
}

.form-control{
    font-size: 15px;
    background: #FFF;
    padding: 15px;
    height: auto;
    border-radius: 0;
    border: 1px solid #CCC;
}


header{
    .logo-block{
        padding-top: 20px;
        padding-bottom: 20px;
        @media(max-width: 992px){
            img{
               width: 200px; 
            }
        }
    }
    .navbar-dark  .navbar-nav{
        .nav-item{            
            margin: 0 1px;
            .nav-link{
                font-size: 13px;
                padding: 10px 15px; 
                color: #000;
                opacity: 0.9;
                text-transform: uppercase;
                font-weight: 500;
            }
            &:hover, &.active{
                .nav-link{
                    background: #efefef;                    
                }
            }
            .dropdown-menu{
                min-width: 100%;
                background: #636363;
                border: none;
                border-radius: 0;
                padding: 0;
                margin: 0;
                -webkit-box-shadow: 0px 40px 43px 0px rgba(88, 88, 88, 0.4);
                -moz-box-shadow: 0px 40px 43px 0px rgba(88, 88, 88, 0.4);
                box-shadow: 0px 40px 43px 0px rgba(88, 88, 88, 0.4);
                .dropdown-item{
                    color: #000;
                    padding: 10px;
                    &:hover, &.active, &:focus, &:active{
                        background: #94ce42;
                    }
                }
            }
        }
    }
    .line{
        background: #444;
        padding: 15px 0;
        .today{
            color: #FFF;
            font-size: 16px;
        }
    }
}

footer{
    padding: 20px 0;
    color: #FFF;
    background: #444;
    font-size: 14px;
    a{
        color: #FFF;
        &:hover{
            color: #FFF;
            text-decoration: underline;
        }
    }
    .title{
        font-weight: bold;
        margin-bottom: 5px;
        display: inline-block;
    }
    ul{
        padding: 0;
        margin: 0;
        margin-bottom: 15px;
        li{
            list-style: none;
            padding: 1px 0;
            margin: 0;
        }
    }
    .footer-menu{
        display: flex;
        gap: 20px;
        @media(max-width: 992px){
            display: block;
            .item{
                border-bottom: 1px solid #555;
                padding: 5px 0;
            }
        }
    }
}

.site_form{
    .form-check-label{
        a{
            color: inherit;
            text-decoration: underline;
        }
    }
    .error-item{
        font-size: 13px;
        color: red;
    }
    .error-field{
        border-color: red;
    }
    .buttons{
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        margin-top: 20px;
        .button-block{
            height: calc(100% - 3px);
            .btn{
                min-height: 100%;
            }
        }
        .recaptcha-block{
            .error-item{
                position: absolute;
            }
        }
    }
    label{
        font-size: 13px;
        font-weight: 500;
        .form-required-star{
            color: red;
        }
    }
    .form-group{
        margin-top: 15px;
    }
    .form-title{
        display: none;
    }
} 
.modal{
    .modal-content{
        border-radius: 0;
    }
    form{
        .buttons{
            margin-top: 0;
            display: block;
            .btn{
                min-height: 0 !important;
                display: block;
                width: 100%;
                margin-top: 20px;
            }
        }
        label, .form-title{
            display: none;
        }
        .form-group{
            margin-bottom: 20px;
        }
        .recaptcha-block .error-item{
            position: relative !important;
        }
    }
}
.main-banner{
    margin-top: 20px;
    a{
        display: block;
        width: 100%;
        font-size: 26px;
        padding: 50px 30px;
        text-align: center;
        font-weight: 600;
        border: 1px solid #CCC;
        background: #FFF;
        color: #444;
        span{
            color: #699c33;
        }
    }
}
.page-detail{
    background: #FFF;
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #f2f2f2;
    .date{
        font-size: 14px;
        opacity: 0.7;
        margin-bottom: 10px;
    }
    .image{
        float: left;
        margin: 0 10px 10px 0;
    }
}
h1.title{
    font-size: 26px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
}
.news-list{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .item{
        background: #FFF;
        padding: 0;
        flex: 0 1 calc(25% - 15px);
        color: #444;
        .info{
            padding: 10px;
            .date{
                font-size: 13px;
                margin-bottom: 5px;
            }
            .name{
                font-size: 15px;
                font-weight: 600;
                margin-bottom: 10px;
            }
            .preview{
                margin-top: 5px;
                font-size: 13px;
            }
        }
        .image{
            overflow: hidden;
            width: 100%;
            padding-top: 60%;
            position: relative;
            background-color: #FFF;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
        &:hover{
            -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.09);
            -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.09);
            box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.09);
        }
    }
    @media (min-width: 400px) {
        &.news-list-page{
            .item{
                flex: 0 1 100%;
                display: flex;
                align-items: center;
                .image{
                    width: 100px;
                    padding-top: 60px;                
                    min-width: 100px;
                    height: 100%;
                }
                .name{
                    margin-bottom: 0;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .item{
            flex: 0 0 100% !important;
        }
    }
}
h2.title{
    color: #444;
    font-size: 24px;
    font-weight: bold;
    padding: 0;
    margin: 0;
}
.other-news > .title{
    color: #444;
    font-size: 24px;
    font-weight: bold;
    padding: 0;
    margin: 0;
}
h1.title{
    color: #444;
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0;
}
.all-news-button-block{
    position: relative;
    .btn-outline-primary-all-news{
        background: #FFF;
        padding: 10px;
        border: none;
        font-weight: 600;
        border: 1px solid #CCC;
        color: #444;
        z-index: 10;
        position: relative;
        &:hover{
            background: #94ce42;
        }
    }
    &:after{
        content: "";
        width: 100%;
        height: 1px;
        background: #CCC;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 1;
    }
}
.breadcrumb{
    font-size: 14px;
    padding: 5px 0;
    color: #444;
    .breadcrumb-item.active{
        color: #444;
    }
    a{
        color: #444;
        opacity: 0.7;
        &:hover{
            opacity: 1;
        }
    }
}
.pagination{
    margin-top: 20px;
    justify-content: center;
}
.navbar .navbar-toggler{
    font-size: 24px;
    color: #444;
    border: none;
    box-shadow: none;
}
@media(max-width: 992px){
    header .navbar-dark .navbar-nav .nav-item.active .nav-link{
        background: #525252;
    }
    header .navbar-dark .navbar-nav .nav-item .nav-link {
        font-size: 12px;
        padding: 10px !important;
        color: #FFF;
    }
}