@media (min-width: 1200px){
    .container {
        max-width: 1240px;
    }
}

@media all and (min-width: 992px) {
    .navbar .nav-item .dropdown-menu{ display: none; }
    .navbar .nav-item:hover .nav-link{   }
    .navbar .nav-item:hover .dropdown-menu{ display: block; }
    .navbar .nav-item .dropdown-menu{ margin-top:0; }
}

 
@media (max-width: 1200px){
    header .navbar-dark .navbar-nav .nav-item .nav-link{
        font-size: 12px;
    }
}

@media (max-width: 992px){
    header{
        padding: 10px 0;        
        .navbar-dark .navbar-toggler-icon{
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
        .navbar-dark .navbar-toggler{
            border: none;
        }
        .navbar-dark .navbar-toggler[aria-expanded=true] .navbar-toggler-icon{
            background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.875 7.875L28.125 28.125' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.875 28.125L28.125 7.875' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
        .navbar{
            position: initial;
        }
        .navbar-collapse{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            background: rgba(34, 34, 34, 1);
            top: 75px;
            .nav-item{
                text-align: left;
                .nav-link{
                    padding: 20px !important;
                }
            }
            .dropdown-toggle::after{
                position: absolute;
                right: 20px;
                top: 28px;
                font-size: 16px;
            }
            .dropdown-menu{
                box-shadow: none !important;
                .dropdown-item{
                    padding: 10px 20px;
                    font-weight: 300;
                    border-bottom: 1px solid #7c7c7c;
                }
                li:last-child{
                    .dropdown-item{
                        border: none;
                    }
                }
            }
        }
    }
}
@media (max-width: 768px){
    .site_form{
        .buttons{
            display: block;
            .button-block .btn{
                min-height: 0;
                display: block;
                margin-top: 20px;
                width: 100%;
            }
        }
    }
}
@media (max-width: 380px){
    .g-recaptcha {
        transform:scale(0.83);
        transform-origin:0 0;
    }
}